<template>
    <div class="likeMarket">
        <div class="likeMarket-header">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/admin/newstorecustomer' }">新店铺客服管理</el-breadcrumb-item>
                <el-breadcrumb-item>直播销售</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 20px">
                <IndustrySelection ref="IndustrySelection" @selectListChange="selectListChange" text="行业分类：" :selectList="selectList"></IndustrySelection>
                <div>
<!--                    <el-button class="btn-blue" @click="sendBtn" style="display: inline-block;">发送</el-button>-->
                    <el-button class="btn-blue" @click="addBtn">创建直播销售数据</el-button>
                </div>
            </div>
        </div>
        <div class="content">
            <el-table ref="multipleTable" :data="serviceData" border style="width: 100%; margin-top: 20px; flex: 1"" size="medium" :header-cell-style="{
                fontWeight: 'normal',height: '60px',color: '#13131B',background: '#F2F3FA',}" :cell-style="{ fontSize: '14px', color: '#343441' }">
                <el-table-column align="center" prop="name" label="直播销售名称"></el-table-column>
                <el-table-column prop="category" align="center" label="行业分类"></el-table-column>
                <el-table-column label="操作" align="center" width="200" :resizable="false">
                    <template slot-scope="scope">
                        <el-link :underline="false" class="link-blue" @click="copyLink(scope.row)">复制</el-link>&nbsp;
                        <el-link :underline="false" class="link-blue" @click="editLink(scope.row)">编辑</el-link>&nbsp;
                        <el-link :underline="false" type="danger" @click="deleteLink(scope.row)">删除</el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination class="pager-center" style="margin-top: 10px" :current-page="liveMarket.page" :page-size="liveMarket.page_size" :total="liveMarket.total"
            layout="prev, pager, next, jumper"
            @current-change="currentChange">
        </el-pagination>
    </div>
</template>

<script>
import IndustrySelection from "../../../components/admin/IndustrySelection";
import {getNewkefuCopy, getNewKeFuListData, getNewkefuSend} from "@/utils/apis";
export default {
    name: "liveMarket",
    components: {
        IndustrySelection
    },
    data() {
        return {
            selectList: [],
            serviceData: [],
            liveMarket: {
                page: 1,
                page_size: 10,
                total: 0,
            },
            selectVal: void 0
        }
    },
    created() {
        this.getKeFuGetIC()
        this.getLiveSaleList()
    },
    methods: {
        // 获取行业分类
        getKeFuGetIC() {
            this.$http.axiosGetBy(this.$api.categoryList, {}, (res) => {
                if (res.code === 200) {
                    this.selectList = res.data;
                } else {
                    this.$message.warning(res.msg);
                }
            });
        },
        // 获取列表数据
        getLiveSaleList() {
            this.$http.axiosGetBy(this.$api.live_saleList, {
                category: this.selectVal,
                paging: 1,
                page: this.liveMarket.page,
                page_size: this.liveMarket.page_size
            }, (res) => {
                if (res.code === 200) {
                    this.serviceData = res.data.list
                    this.liveMarket.total = res.data.total
                } else {
                    this.$message.warning(res.msg);
                }
            });
        },
        selectListChange(id) {
            this.selectVal = id;
            this.getLiveSaleList()
        },
        // 获取页面数据
        getListData(id) {
        },
        // 点击创建直播销售
        addBtn() {
            this.$router.push("/admin/liveMarket/createLiveMarket");
        },
        copyLink(row) {
            this.$confirm(`是否复制一个副本？`, {
                confirmButtonText: "确定复制",
                cancelButtonText: "取消",
                confirmButtonClass: 'btn-blue',
                type: "warning",
            }).then(() => {
                this.$http.axiosGetBy(this.$api.live_saleCopy, {
                    id: row.id
                }, (res) => {
                    if (res.code === 200) {
                        this.getLiveSaleList()
                        this.$message.success(res.msg);
                    } else {
                        this.$message.warning(res.msg);
                    }
                });
            }).catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消复制",
                });
            });
        },
        editLink(row) {
            this.$router.push({
                path: '/admin/liveMarket/createLiveMarket',
                query: {
                    id: row.id
                }
            })
        },
        deleteLink(row) {
            this.$confirm(`此操作将永久删除，是否继续`, {
                confirmButtonText: "确定删除",
                cancelButtonText: "取消",
                confirmButtonClass: 'btn-blue',
                type: "warning",
            }).then(() => {
                this.$http.axiosPost(this.$api.live_saleDelete, {
                    id: row.id + ''
                }, (res) => {
                    if (res.code === 200) {
                        this.getLiveSaleList()
                        this.$message.success(res.msg);
                    } else {
                        this.$message.warning(res.msg);
                    }
                });
            }).catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消删除",
                });
            });
        },
        currentChange(val) {
            this.liveMarket.page = val
            this.getLiveSaleList()
        }
    }
}
</script>

<style scoped lang="scss">
    .likeMarket {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .likeMarket-header {

        }
        .content {
            flex: 1;
        }
    }
</style>